import {
  type DtoBlock,
  EnumsBlockGradeResult,
  type EnumsBlockType,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import {
  type BlockType,
  BlockTypeV2,
  blockTypeV2fromEnumsBlockType,
  type DrawToWinBlock,
  type FillInTheBlanksBlock,
  fromAPIBlockType,
  type HiddenPictureBlock,
  type JeopardyBlock,
  type MemoryMatchBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
  type RoleplayBlock,
  type ScenarioBlock,
  type SwipeToWinBlock,
} from '@lp-lib/game';

import { fromDTOBlock } from '../../../utils/api-dto';
import { assertExhaustive } from '../../../utils/common';
import { blockOutputsToGradeResult as drawToWinBO2GR } from './DrawToWin/outputs';
import { blockOutputsToGradeResult as fillInTheBlanksBO2GR } from './FillInTheBlanks/outputs';
import { blockOutputsToGradeResult as hiddenPictureBO2GR } from './HiddenPicture/outputs';
import { blockOutputsToGradeResult as jeopardyBO2GR } from './Jeopardy/outputs';
import { blockOutputsToGradeResult as matchBO2GR } from './Match/outputs';
import { blockOutputsToGradeResult as mutipleChoiceBO2GR } from './MultipleChoice/outputs';
import { blockOutputsToGradeResult as questionBO2GR } from './Question/outputs';
import { blockOutputsToGradeResult as roleplayBO2GR } from './Roleplay/outputs';
import { blockOutputsToGradeResult as scenarioBO2GR } from './Scenario/outputs';
import { blockOutputsToGradeResult as swipeToWinBO2GR } from './SwipeToWin/outputs';

export type BlockGradeResult = {
  blockId: string;
  blockType: BlockType;
  status: EnumsBlockGradeResult;
  label: string; // Passed/Failed or 100/100 pts
  context: string;
  earnedPoints: number;
  totalPoints: number;
};

export function blockTypePlayable(blockType: EnumsBlockType) {
  const type = blockTypeV2fromEnumsBlockType(blockType);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE:
    case BlockTypeV2.QUESTION:
    case BlockTypeV2.MATCH:
    case BlockTypeV2.ROLEPLAY:
    case BlockTypeV2.DRAW_TO_WIN:
    case BlockTypeV2.HIDDEN_PICTURE:
    case BlockTypeV2.JEOPARDY:
    case BlockTypeV2.FILL_IN_THE_BLANKS:
    case BlockTypeV2.SWIPE_TO_WIN:
    case BlockTypeV2.SCENARIO:
      return true;
    case BlockTypeV2.SLIDE:
    case BlockTypeV2.RESULTS:
    case BlockTypeV2.SPARKIFACT: // it's playable, but no score so far.
      return false;
    case null:
      return null;
    default:
      assertExhaustive(type);
      return null;
  }
}

function makeUnsupportedGradeResult(block: DtoBlock): BlockGradeResult {
  return {
    blockId: block.id,
    blockType: fromAPIBlockType(block.type),
    status: EnumsBlockGradeResult.BlockGradeResultFailed,
    label: 'Failed',
    context: 'Unsupported',
    earnedPoints: 0,
    totalPoints: 0,
  };
}

export function blockOutputsToGradeResult(
  block: DtoBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock = false
): BlockGradeResult {
  const b = fromDTOBlock(block);
  const type = blockTypeV2fromEnumsBlockType(block.type);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE:
      return mutipleChoiceBO2GR(b as MultipleChoiceBlock, outputs, mock);
    case BlockTypeV2.QUESTION:
      return questionBO2GR(b as QuestionBlock, outputs, mock);
    case BlockTypeV2.MATCH:
      return matchBO2GR(b as MemoryMatchBlock, outputs, mock);
    case BlockTypeV2.ROLEPLAY:
      return roleplayBO2GR(b as RoleplayBlock, outputs, mock);
    case BlockTypeV2.DRAW_TO_WIN:
      return drawToWinBO2GR(b as DrawToWinBlock, outputs, mock);
    case BlockTypeV2.HIDDEN_PICTURE:
      return hiddenPictureBO2GR(b as HiddenPictureBlock, outputs, mock);
    case BlockTypeV2.JEOPARDY:
      return jeopardyBO2GR(b as JeopardyBlock, outputs, mock);
    case BlockTypeV2.FILL_IN_THE_BLANKS:
      return fillInTheBlanksBO2GR(b as FillInTheBlanksBlock, outputs, mock);
    case BlockTypeV2.SWIPE_TO_WIN:
      return swipeToWinBO2GR(b as SwipeToWinBlock, outputs, mock);
    case BlockTypeV2.SCENARIO:
      return scenarioBO2GR(b as ScenarioBlock, outputs, mock);
    case BlockTypeV2.SLIDE:
    case BlockTypeV2.RESULTS:
    case BlockTypeV2.SPARKIFACT:
    case null:
      return makeUnsupportedGradeResult(block);
    default:
      assertExhaustive(type);
      return makeUnsupportedGradeResult(block);
  }
}
