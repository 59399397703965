import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type MemoryMatchBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomBetween } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  mismatchCount: {
    name: 'mismatchCount',
    description: 'The number of times the user mismatched two items.',
    schema: {
      type: 'number',
    },
  },
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  cardPairsCount: {
    name: 'cardPairsCount',
    description: 'The number of card pairs in the game.',
    schema: {
      type: 'number',
    },
  },
  matchedCount: {
    name: 'matchedCount',
    description: 'The number of card pairs the user has matched.',
    schema: {
      type: 'number',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type MemoryMatchBlockOutputSchema = typeof outputSchema;
export const rawMemoryMatchBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: MemoryMatchBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: MemoryMatchBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question = getBlockOutputAsString(outputs[schema.question.name]);
  const cardPairsCount =
    getBlockOutputAsNumber(outputs[schema.cardPairsCount.name]) ||
    block.fields.cardPairs.length;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    cardPairsCount * block.fields.pointsPerMatch;
  let matchedCount = getBlockOutputAsNumber(outputs[schema.matchedCount.name]);
  let mismatchCount = getBlockOutputAsNumber(
    outputs[schema.mismatchCount.name]
  );
  if (mock) {
    matchedCount = matchedCount || randomBetween(0, cardPairsCount);
    mismatchCount = cardPairsCount - matchedCount;
  }
  const earnedPoints =
    getBlockOutputAsNumber(outputs[schema.points.name]) ||
    matchedCount * block.fields.pointsPerMatch;
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      cardPairsCount === matchedCount
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `Question: ${question}`,
      `Mismathed Attempts: ${mismatchCount}`,
      `Total Attempts: ${mismatchCount + matchedCount}`,
    ].join('\n'),
  };
}
