import {
  type EnumsJeopardyClueType,
  type EnumsJeopardyTurnResult,
  type ModelsJeopardyTurnHistory,
} from '@lp-lib/api-service-client/public';
import { type MultipleChoiceOption } from '@lp-lib/game';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';

export const JEOPARDY_TURNS_COUNT = getFeatureQueryParamNumber(
  'jeopardy-turns-count'
);
export const JEOPARDY_BOARD_FILL_DURATION_SEC = 3;
export const JEOPARDY_SKIP_INTRO = getFeatureQueryParam('jeopardy-skip-intro');
export const JEOPARDY_SKIP_CATEGORIES_PRESENTATION = getFeatureQueryParam(
  'jeopardy-skip-categories-presentation'
);

export type JeopardyPlayer = {
  uid: string;
  username: string;
  icon: string;
  score: number;
  order: number;
  isMe: boolean;
  history: ModelsJeopardyTurnHistory[];
};

export type JeopardyTurn = {
  clue: JeopardyGameClue;
  result: EnumsJeopardyTurnResult;
};

export type JeopardyGameClue = {
  id: string;
  type: EnumsJeopardyClueType;
  question: string;
  answer: string;
  points: number;
  status: 'init' | 'present' | 'selectable' | 'played';
  answerChoices: MultipleChoiceOption[];
};

export type JeopardyGameCategory = {
  id: string;
  name: string;
  clues: JeopardyGameClue[];
  status: 'init' | 'present';
};

export type JeopardyGameBoard = {
  categories: JeopardyGameCategory[];
};

export type JeopardyGameState = {
  board: JeopardyGameBoard;
  playedTurns: JeopardyTurn[];
  players: JeopardyPlayer[];
  stage: JeopardyStage;
  // the maximum points that can be earned in this game (include daily double)
  totalPoints: number;
};

export type JeopardyStageInit = {
  state: 'init';
};

export type JeopardyStageIntro = {
  state: 'intro';
  showWelcome: boolean;
};

export type JeopardyStageInitBoard = {
  state: 'init-board';
  animate?: 'slide-in' | 'filling' | 'done';
};

export type JeopardyStagePresentCategories = {
  state: 'present-categories';
  index: number;
  category?: JeopardyGameCategory | null;
};

export type JeopardyStageWaitingClueSelection = {
  state: 'waiting-clue-selection';
};

export type JeopardyStagePlayClue = {
  state: 'play-clue';
  clue: JeopardyGameClue;
  isWagerTurn: boolean;

  wagerEnabled?: boolean;
  animateClueCellBlinking?: boolean;
  animateClueCellScaleUp?: boolean;
  animateDailyDouble?: boolean;
  showWager?: boolean;
  showCluePlayground?: boolean;
};

export type JeopardyStageResult = {
  state: 'result';
};

export type JeopardyStage =
  | JeopardyStageInit
  | JeopardyStageIntro
  | JeopardyStageInitBoard
  | JeopardyStagePresentCategories
  | JeopardyStageWaitingClueSelection
  | JeopardyStagePlayClue
  | JeopardyStageResult;
