import {
  DtoProgression,
  ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { Block } from './block';

export type BlockOutputKey = `${string}.${string}`;

export function getBlockOutputKey(
  blockId: Block['id'],
  outputName: string
): BlockOutputKey {
  return `${blockId}.${outputName}`;
}

export function getBlockOutputAsString(
  output?: ModelsBlockOutput | null,
  fallback = ''
): string {
  if (!output) return fallback;
  switch (output.type) {
    case 'string':
    case 'number':
    case 'enum':
      return String(output.value);
  }
  return fallback;
}

export function getBlockOutputAsNumber(
  output?: ModelsBlockOutput | null,
  fallback = 0
): number {
  if (!output) return fallback;
  switch (output.type) {
    case 'number':
      return output.value;
    case 'string':
    case 'enum':
      return Number(output.value);
  }
  return fallback;
}

export function appendBlockReferenceOutputs(
  outputs: DtoProgression['blockOutputs'],
  blockMap: Map<Block['id'], Block>
): DtoProgression['blockOutputs'] {
  if (!outputs) return outputs;
  for (const [key, output] of Object.entries(outputs ?? {})) {
    const [blockId, outputName] = key.split('.', 2) as [Block['id'], string];
    const block = blockMap.get(blockId);
    if (block?.fields.referenceId) {
      outputs[getBlockOutputKey(block.fields.referenceId, outputName)] = output;
    }
  }
  return outputs;
}

export function getBlockOutputsById(
  targetBlockId: Block['id'],
  outputs: DtoProgression['blockOutputs']
): Record<string, ModelsBlockOutput> {
  const blockOutputs: Record<string, ModelsBlockOutput> = {};
  for (const [key, output] of Object.entries(outputs ?? {})) {
    const [blockId, outputName] = key.split('.', 2) as [Block['id'], string];
    if (blockId !== targetBlockId) continue;
    blockOutputs[outputName] = output;
  }
  return blockOutputs;
}

export type BlockOutputSchema =
  | {
      type: 'string';
    }
  | {
      type: 'number';
    }
  | {
      type: 'enum';
      values: string[];
    };

export type BlockOutputDesc<Name extends string = string> = {
  name: Name;
  displayName?: string;
  schema: BlockOutputSchema;
  description?: string;
};

export type BlockOutputsDesc = {
  [key: BlockOutputDesc['name']]: BlockOutputDesc;
};

// ensures consistent output names
export function defineBlockOutputs<
  T extends { [K in keyof T]: BlockOutputDesc & { name: K } }
>(schema: T): T {
  return schema;
}

export type InferSchemaOutputValueType<S> = S extends { type: 'string' }
  ? string
  : S extends { type: 'number' }
  ? number
  : S extends { type: 'enum'; values: readonly (infer U)[] }
  ? U
  : never;

// Transforms block outputs into a type that can be used to type outputs in assessment results
export type AssessmentResultOutputs<
  T extends Record<string, { name: string; schema: BlockOutputSchema }>
> = {
  [K in T[keyof T]['name']]: Extract<T[keyof T], { name: K }> extends {
    schema: infer S;
  }
    ? {
        type: S extends { type: infer U } ? U : never;
        value: InferSchemaOutputValueType<S>;
      }
    : never;
};

/**
 * Creates a subschema by picking specific keys from a larger schema.
 *
 * @template T - Type extending BlockOutputsDesc describing the original output schema
 * @template K - Type of keys to pick from the schema
 *
 * @param {T} schema - The original schema
 * @param {Array<K>} keys - Array of keys to pick from the schema
 *
 * @returns {Pick<T, K>} - A new schema containing only the selected keys
 *
 * @example
 * // Create a subschema with only the 'answer' field
 * const answerSchema = pickBlockOutputSchema(fullSchema, ['answer']);
 */
export function pickBlockOutputSchema<
  T extends BlockOutputsDesc,
  K extends keyof T
>(schema: T, keys: Array<K>): Pick<T, K> {
  const result = {} as Pick<T, K>;

  keys.forEach((key) => {
    if (schema[key]) {
      result[key] = schema[key];
    }
  });

  return result;
}

/**
 * Validates block assessment outputs against a schema and returns a typed result.
 *
 * This function takes a record of block outputs and validates them against a provided schema.
 *
 * @template T - Type extending BlockOutputsDesc describing the output schema
 *
 * @param {Record<string, ModelsBlockOutput>} outputs - The block outputs to validate
 * @param {T} schema - The schema to validate against
 *
 * @returns {Object} - A result object with either:
 *   - { success: true, data: AssessmentResultOutputs<T> } - When validation succeeds
 *   - { success: false, error: string } - When validation fails
 *
 * @example
 * // Validate against the full schema
 * const result = validateBlockAssessmentOutputs(outputs, fullSchema);
 * if (result.success) {
 *   // Can access all properties defined in the schema
 *   const value = result.data.someProperty.value;
 * }
 *
 * @example
 * // Validate against a subset of the schema
 * const answerSchema = pickBlockOutputSchema(fullSchema, ['answer']);
 * const result = validateBlockAssessmentOutputs(outputs, answerSchema);
 * if (result.success) {
 *   // Can only access the 'answer' property
 *   const value = result.data.answer.value;
 *   // Trying to access result.data.grade would result in a TypeScript error
 * }
 */
export function validateBlockAssessmentOutputs<T extends BlockOutputsDesc>(
  outputs: Record<string, ModelsBlockOutput>,
  schema: T
):
  | { success: true; data: AssessmentResultOutputs<T> }
  | { success: false; error: string } {
  // Validate that all output keys exist in the schema
  const outputKeys = Object.keys(outputs);
  const schemaKeys = Object.keys(schema);

  if (!schemaKeys.every((key) => outputKeys.includes(key))) {
    return { success: false, error: 'Output keys do not match schema keys' };
  }

  // Transform all outputs according to the schema
  const result = {} as AssessmentResultOutputs<T>;

  for (const [key, output] of Object.entries(outputs)) {
    const schemaItem = schema[key as keyof T];

    if (schemaItem) {
      const outputName = schemaItem.name;

      // Create a properly typed output value
      const typedOutput = {
        type: output.type,
        value: output.value,
      } as AssessmentResultOutputs<T>[keyof AssessmentResultOutputs<T>];

      Object.assign(result, { [outputName]: typedOutput });
    }
  }

  return { success: true, data: result };
}
