import shuffle from 'lodash/shuffle';

import {
  type DtoJeopardySession,
  EnumsJeopardyClueType,
  EnumsJeopardyTurnResult,
  type ModelsJeopardyTurnHistory,
} from '@lp-lib/api-service-client/public';
import {
  type JeopardyBlock,
  type JeopardyBoard,
  type JeopardyCategory,
  type JeopardyClue,
} from '@lp-lib/game';

import { type BaseUser } from '../../../../types';
import { getRandomAnimatedAvatarVariant } from '../../../Participant/avatars';
import {
  JEOPARDY_TURNS_COUNT,
  type JeopardyGameBoard,
  type JeopardyGameState,
  type JeopardyPlayer,
} from './types';

export class JeopardyUtils {
  static AdaptToGameBoard(board: JeopardyBoard): JeopardyGameBoard {
    return {
      categories:
        board.categories?.slice(0, 3).map((category) => ({
          id: category.id,
          name: category.name,
          clues: category.clues.slice(0, 3).map((clue) => ({
            id: clue.id,
            type:
              clue.type ?? EnumsJeopardyClueType.JeopardyClueTypeMultipleChoice,
            question: clue.question,
            answer: clue.answer,
            answerChoices: clue.answerChoices || [],
            points: clue.value,
            status: 'init',
          })),
          status: 'init',
        })) || [],
    };
  }

  static MakeInitialGameState(
    block: JeopardyBlock,
    user: BaseUser
  ): JeopardyGameState {
    const board = this.AdaptToGameBoard(block.fields.board);

    return {
      board,
      stage: { state: 'init' },
      playedTurns: [],
      players: this.MockPlayers(user, board),
      totalPoints: 0,
    };
  }

  static MockPlayers(user?: BaseUser, board?: JeopardyGameBoard | null) {
    const players: JeopardyPlayer[] = [];
    if (user) {
      players.push(this.NewMyPlayer(user));
    } else {
      players.push(this.NewMockedPlayer(0, board));
    }
    // players.push(this.NewMockedPlayer(1, board));
    // players.push(this.NewMockedPlayer(2, board));
    return players;
  }

  static NewMyPlayer(user: BaseUser): JeopardyPlayer {
    return {
      uid: user.id,
      username: user.username,
      isMe: true,
      score: 0,
      order: 0,
      icon: getRandomAnimatedAvatarVariant(0, user.id),
      history: [],
    };
  }

  static NewPlayerFromSession(
    session: DtoJeopardySession,
    index: number
  ): JeopardyPlayer {
    return {
      uid: session.uid,
      username: session.username ?? `Player ${index + 1}`,
      isMe: false,
      score: 0,
      order: index,
      icon: getRandomAnimatedAvatarVariant(index, session.uid),
      history: session.data.history,
    };
  }

  static NewMockedPlayer(
    index: number,
    board?: JeopardyGameBoard | null
  ): JeopardyPlayer {
    return {
      uid: `${index}`,
      username: `Player ${index + 1}`,
      isMe: false,
      score: 0,
      order: index,
      icon: getRandomAnimatedAvatarVariant(index, `${index}`),
      history: this.SimulatePlayHistory(board),
    };
  }

  static SimulatePlayHistory(board?: JeopardyGameBoard | null) {
    if (!board) return [];

    const clues = board.categories.flatMap((c) => c.clues);
    const shuffled = shuffle(clues);
    const playClues = shuffled.slice(0, JEOPARDY_TURNS_COUNT);
    let total = 0;
    const history: ModelsJeopardyTurnHistory[] = [];
    for (const clue of playClues) {
      const skipped = Math.random() < 0.2;
      if (skipped) {
        history.push({
          clueId: clue.id,
          result: EnumsJeopardyTurnResult.JeopardyTurnResultSkipped,
          points: 0,
          total,
        });
        continue;
      }
      const correct = Math.random() < 0.8;
      if (correct) {
        total += clue.points;
        history.push({
          clueId: clue.id,
          result: EnumsJeopardyTurnResult.JeopardyTurnResultCorrect,
          points: clue.points,
          total,
        });
        continue;
      }

      total -= clue.points;
      history.push({
        clueId: clue.id,
        result: EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect,
        points: -clue.points,
        total,
      });
    }
    return history;
  }

  static FindClue(board: JeopardyBoard, clueId?: string | null) {
    if (!board.categories || !clueId) return null;

    for (const category of board.categories) {
      const clue = category.clues.find((c) => c.id === clueId);
      if (clue) return clue;
    }

    return null;
  }

  static UpdateBoardClue(board: JeopardyBoard, clue: JeopardyClue) {
    if (!board.categories) return board;

    for (const category of board.categories) {
      const index = category.clues.findIndex((c) => c.id === clue.id);
      if (index === -1) continue;

      const newClues = [...category.clues];
      newClues[index] = clue;
      const newCategory = { ...category, clues: newClues };
      return this.UpdateBoardCategory(board, newCategory);
    }

    return board;
  }

  static UpdateBoardCategory(board: JeopardyBoard, category: JeopardyCategory) {
    if (!board.categories) return board;

    const index = board.categories.findIndex((c) => c.id === category.id);
    if (index === -1) return board;

    const newCategories = [...board.categories];
    newCategories[index] = category;
    const newBoard = { ...board, categories: newCategories };
    return newBoard;
  }
}
