import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type QuestionBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  submission: {
    name: 'submission',
    description: 'Set when the user inputs a response to the block.',
    schema: {
      type: 'string',
    },
  },
  grade: {
    name: 'grade',
    description: 'Set when the block evaluates the user selection.',
    schema: {
      type: 'enum',
      values: ['correct', 'incorrect'] as const,
    },
  },
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  answer: {
    name: 'answer',
    description: 'The correct answer',
    schema: {
      type: 'string',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type QuestionBlockOutputSchema = typeof outputSchema;
export const rawQuestionBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: QuestionBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: QuestionBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.question;
  const answer =
    getBlockOutputAsString(outputs[schema.answer.name]) || block.fields.answer;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    block.fields.points;
  let submission = getBlockOutputAsString(outputs[schema.submission.name]);
  let grade = getBlockOutputAsString(outputs[schema.grade.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    grade ||= randomPick(['correct', 'incorrect']);
    if (!earnedPoints) {
      earnedPoints = grade === 'correct' ? totalPoints : 0;
    }
    if (!submission) {
      submission = grade === 'correct' ? answer : "I don't know";
    }
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      grade === 'correct'
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `Question: ${question}`,
      `Correct Answer: ${answer}`,
      `User Answer: ${submission}`,
    ].join('\n'),
  };
}
