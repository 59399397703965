import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type FillInTheBlanksBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomBetween } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  sentence: {
    name: 'sentence',
    description: 'The full sentence',
    schema: {
      type: 'string',
    },
  },
  blanksCount: {
    name: 'blanksCount',
    description: 'The number of blanks in the sentence',
    schema: {
      type: 'number',
    },
  },
  correctCount: {
    name: 'correctCount',
    description: 'The number of correct answers',
    schema: {
      type: 'number',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type FillInTheBlanksBlockOutputSchema = typeof outputSchema;
export const rawFillInTheBlanksBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: FillInTheBlanksBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: FillInTheBlanksBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const sentence =
    getBlockOutputAsString(outputs[schema.sentence.name]) ||
    block.fields.segments.map((seg) => seg.text).join('');
  const blanksCount =
    getBlockOutputAsNumber(outputs[schema.blanksCount.name]) ||
    block.fields.segments.filter((s) => s.type === 'blank').length;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    blanksCount * block.fields.pointsPerCorrect;
  let correctCount = getBlockOutputAsNumber(outputs[schema.correctCount.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    correctCount ||= randomBetween(0, blanksCount);
    earnedPoints ||= correctCount * block.fields.pointsPerCorrect;
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      blanksCount === correctCount
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `Correct Text: ${sentence}`,
      `Result: User correctly entered ${correctCount}/${blanksCount} words`,
    ].join('\n'),
  };
}
