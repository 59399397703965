import cloneDeep from 'lodash/cloneDeep';

import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type MultipleChoiceBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  choice: {
    name: 'choice',
    description: 'Set when the user selects one of the presented choices.',
    schema: {
      type: 'enum',
      values: [] as string[],
    },
  },
  grade: {
    name: 'grade',
    description: 'Set when the block evaluates the user selection.',
    schema: {
      type: 'enum',
      values: ['correct', 'incorrect', 'skipped'] as const,
    },
  },
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  answer: {
    name: 'answer',
    description: 'The correct answer',
    schema: {
      type: 'string',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type MultipleChoiceBlockOutputSchema = typeof outputSchema;
export const rawMultipleChoiceBlockOutputSchema = outputSchema;

export function getOutputSchema(block: MultipleChoiceBlock) {
  const schema = cloneDeep(outputSchema);
  schema.choice.schema = {
    type: 'enum',
    values: block.fields.answerChoices.map((opt) => opt.text),
  };
  return schema;
}

export function blockOutputsToGradeResult(
  block: MultipleChoiceBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.question;
  const answer =
    getBlockOutputAsString(outputs[schema.answer.name]) ||
    block.fields.answerChoices.find((opt) => opt.correct)?.text ||
    '';
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    block.fields.points;
  let choice = getBlockOutputAsString(outputs[schema.choice.name]);
  let grade = getBlockOutputAsString(outputs[schema.grade.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    grade ||= randomPick(['correct', 'incorrect']);
    if (!earnedPoints) {
      earnedPoints = grade === 'correct' ? block.fields.points : 0;
    }
    if (!choice) {
      choice = grade === 'correct' ? answer : "I don't know";
    }
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      grade === 'correct'
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints: earnedPoints,
    totalPoints: totalPoints,
    context: [
      `Question: ${question}`,
      `Correct Answer: ${answer}`,
      `User Answer: ${choice}`,
    ].join('\n'),
  };
}
