import capitalize from 'lodash/capitalize';

import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type RoleplayBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  result: {
    name: 'result',
    description: 'The result of the roleplay',
    schema: {
      type: 'enum',
      values: ['succeeded', 'failed'] as const,
    },
  },
  systemPrompt: {
    name: 'systemPrompt',
    description: 'The system prompt',
    schema: {
      type: 'string',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type RoleplayBlockOutputSchema = typeof outputSchema;
export const rawRoleplayBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: RoleplayBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: RoleplayBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const systemPrompt = getBlockOutputAsString(
    outputs[schema.systemPrompt.name]
  );
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    block.fields.points;
  let result = getBlockOutputAsString(outputs[schema.result.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    result = result || randomPick(['succeeded', 'failed']);
    earnedPoints = result === 'succeeded' ? totalPoints : 0;
  }

  const status =
    result === 'succeeded'
      ? EnumsBlockGradeResult.BlockGradeResultPassed
      : EnumsBlockGradeResult.BlockGradeResultFailed;
  return {
    blockId: block.id,
    blockType: block.type,
    status: status,
    label: capitalize(status),
    earnedPoints,
    totalPoints,
    context: [
      `Result: User ${status} this role play.`,
      `<roleplayPrompt>\n${systemPrompt}\n</roleplayPrompt>`,
    ].join('\n'),
  };
}
