import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type JeopardyBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomBetween, randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';
import { JEOPARDY_TURNS_COUNT } from './types';
import { JeopardyUtils } from './utils';

const outputSchema = defineBlockOutputs({
  correctCount: {
    name: 'correctCount',
    description: 'The number of correct answers',
    schema: {
      type: 'number',
    },
  },
  incorrectCount: {
    name: 'incorrectCount',
    description: 'The number of incorrect answers',
    schema: {
      type: 'number',
    },
  },
  correctCategories: {
    name: 'correctCategories',
    description: 'correct answers in these categories',
    schema: {
      type: 'string',
    },
  },
  incorrectCategories: {
    name: 'incorrectCategories',
    description: 'incorrect answers in these categories',
    schema: {
      type: 'string',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type JeopardyBlockOutputSchema = typeof outputSchema;
export const rawJeopardyBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: JeopardyBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: JeopardyBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  let totalPoints = getBlockOutputAsNumber(outputs[schema.totalPoints.name]);
  let correctCategories = getBlockOutputAsString(
    outputs[schema.correctCategories.name]
  );
  let incorrectCategories = getBlockOutputAsString(
    outputs[schema.incorrectCategories.name]
  );
  let correctCount = getBlockOutputAsNumber(outputs[schema.correctCount.name]);
  let incorrectCount = getBlockOutputAsNumber(
    outputs[schema.incorrectCount.name]
  );
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    const board = JeopardyUtils.AdaptToGameBoard(block.fields.board);
    const categories = board.categories;
    if (!totalPoints) {
      for (const c of categories) {
        for (const clue of c.clues) {
          totalPoints += clue.points;
        }
      }
    }
    if (!earnedPoints) {
      earnedPoints = randomBetween(0, totalPoints);
    }
    if (correctCount === 0 && incorrectCount === 0) {
      correctCount = randomBetween(0, JEOPARDY_TURNS_COUNT);
      incorrectCount = JEOPARDY_TURNS_COUNT - correctCount;

      const categoryNames = categories.map((c) => c.name);
      if (incorrectCount > 0) {
        incorrectCategories = randomPick(categoryNames);
      }
      correctCategories = categoryNames
        .filter((c) => c !== incorrectCategories)
        .join(', ');
    }
  }
  const status =
    correctCount >= incorrectCount
      ? EnumsBlockGradeResult.BlockGradeResultPassed
      : EnumsBlockGradeResult.BlockGradeResultFailed;
  return {
    blockId: block.id,
    blockType: block.type,
    status: status,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `${correctCount} correct answers in those cateogires: ${correctCategories}`,
      `${incorrectCount} incorrect answers in those cateogires: ${incorrectCategories}`,
    ].join('\n'),
  };
}
