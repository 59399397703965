import cloneDeep from 'lodash/cloneDeep';
import sum from 'lodash/sum';

import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type HiddenPictureBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomBetween } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  spotsCount: {
    name: 'spotsCount',
    description: 'The number of hot spots in the picture',
    schema: {
      type: 'number',
    },
  },
  foundCount: {
    name: 'foundCount',
    description: 'The number of hot spots found by the user',
    schema: {
      type: 'number',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type HiddenPictureBlockOutputSchema = typeof outputSchema;
export const rawHiddenPictureBlockOutputSchema = outputSchema;

const HOT_SPOT_STATES = ['found', 'not-found'] as const;

// note(falcon): this is a temporary solution to unblock logic for hp blocks, given our output logic currently does
// not support arrays.
export function getOutputSchema(block: HiddenPictureBlock) {
  // Use explicit type to avoid index signature error
  const outputs = cloneDeep(outputSchema) as Record<
    string,
    {
      name: string;
      displayName?: string;
      description: string;
      schema: {
        type: 'string' | 'number' | 'enum';
        values?: readonly string[];
      };
    }
  >;

  const hotSpots = block.fields.pictures?.[0]?.hotSpotsV2;
  if (!hotSpots?.length) return outputs;

  for (const hotSpot of hotSpots) {
    outputs[hotSpot.id] = {
      name: hotSpot.id,
      displayName: hotSpot.name,
      description: '',
      schema: {
        type: 'enum',
        values: [...HOT_SPOT_STATES],
      },
    };
  }

  return outputs;
}

export function blockOutputsToGradeResult(
  block: HiddenPictureBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.instructions;
  const spotsCount =
    getBlockOutputAsNumber(outputs[schema.spotsCount.name]) ||
    block.fields.pictures?.at(0)?.hotSpotsV2?.length ||
    0;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    sum(block.fields.pictures?.at(0)?.hotSpotsV2?.map((hs) => hs.points));
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  let foundCount = getBlockOutputAsNumber(outputs[schema.foundCount.name]);
  if (mock) {
    foundCount ||= randomBetween(0, spotsCount);
    earnedPoints = Math.floor(foundCount * (totalPoints / spotsCount));
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      spotsCount === foundCount
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `Question: ${question}`,
      `Result: User clicked on ${foundCount}/${spotsCount} of the items`,
    ].join('\n'),
  };
}
