import capitalize from 'lodash/capitalize';

import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type DrawToWinBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  grade: {
    name: 'grade',
    description: "Set when the block evaluates the user's drawing.",
    schema: {
      type: 'enum',
      values: ['correct', 'incorrect'] as const,
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type DrawToWinBlockOutputSchema = typeof outputSchema;
export const rawDrawToWinBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: DrawToWinBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: DrawToWinBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.question;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    block.fields.points;
  let grade = getBlockOutputAsString(outputs[schema.grade.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    grade ||= randomPick(['correct', 'incorrect']);
    earnedPoints ||= grade === 'correct' ? totalPoints : 0;
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      grade === 'correct'
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: capitalize(grade),
    earnedPoints,
    totalPoints,
    context: [
      `Question: ${question}`,
      `Result: User ${grade} the exercise`,
    ].join('\n'),
  };
}
