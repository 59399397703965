import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type SwipeToWinBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomBetween } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  question: {
    name: 'question',
    description: 'The question text',
    schema: {
      type: 'string',
    },
  },
  cardPairsCount: {
    name: 'cardPairsCount',
    description: 'The number of card pairs in the game.',
    schema: {
      type: 'number',
    },
  },
  matchedCount: {
    name: 'matchedCount',
    description: 'The number of card pairs the user has matched.',
    schema: {
      type: 'number',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type SwipeToWinBlockOutputSchema = typeof outputSchema;
export const rawSwipeToWinBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: SwipeToWinBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: SwipeToWinBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.instruction;
  const cardPairsCount =
    getBlockOutputAsNumber(outputs[schema.cardPairsCount.name]) ||
    block.fields.cardPairs.length;
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    cardPairsCount * block.fields.pointsPerMatch;
  let matchedCount = getBlockOutputAsNumber(outputs[schema.matchedCount.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    matchedCount ||= randomBetween(0, cardPairsCount);
    earnedPoints ||= matchedCount * block.fields.pointsPerMatch;
  }
  return {
    blockId: block.id,
    blockType: block.type,
    status:
      cardPairsCount === matchedCount
        ? EnumsBlockGradeResult.BlockGradeResultPassed
        : EnumsBlockGradeResult.BlockGradeResultFailed,
    label: `${earnedPoints}/${totalPoints} pts`,
    earnedPoints,
    totalPoints,
    context: [
      `Question: ${question}`,
      `Total Matches: ${cardPairsCount}`,
      `Correct Matches: ${matchedCount}`,
    ].join('\n'),
  };
}
