import capitalize from 'lodash/capitalize';

import {
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { type ScenarioBlock } from '@lp-lib/game';
import {
  defineBlockOutputs,
  getBlockOutputAsNumber,
  getBlockOutputAsString,
} from '@lp-lib/game/src/block-outputs';

import { randomPick } from '../../../../utils/common';
import { type BlockGradeResult } from '../block-grade-result';

const outputSchema = defineBlockOutputs({
  transcript: {
    name: 'transcript',
    description: "The user's response transcript",
    schema: {
      type: 'string',
    },
  },
  responseMediaId: {
    name: 'responseMediaId',
    description: "The media id of the user's response",
    schema: {
      type: 'string',
    },
  },
  result: {
    name: 'result',
    description: 'The result of the scenario',
    schema: {
      type: 'enum',
      values: ['succeeded', 'failed'] as const,
    },
  },
  correctCount: {
    name: 'correctCount',
    description: 'The number of correct items.',
    schema: {
      type: 'number',
    },
  },
  incorrectCount: {
    name: 'incorrectCount',
    description: 'The number of incorrect items.',
    schema: {
      type: 'number',
    },
  },
  question: {
    name: 'question',
    description: 'The question to be asked',
    schema: {
      type: 'string',
    },
  },
  thingsSaidCorrectly: {
    name: 'thingsSaidCorrectly',
    description: 'The things said correctly',
    schema: {
      type: 'string',
    },
  },
  thingsAvoidedSaying: {
    name: 'thingsAvoidedSaying',
    description: 'The things avoided saying',
    schema: {
      type: 'string',
    },
  },
  points: {
    name: 'points',
    description: 'The points the user has earned',
    schema: {
      type: 'number',
    },
  },
  totalPoints: {
    name: 'totalPoints',
    description: 'The total points the user can get in this block',
    schema: {
      type: 'number',
    },
  },
});

export type ScenarioBlockOutputSchema = typeof outputSchema;
export const rawScenarioBlockOutputSchema = outputSchema;

export function getOutputSchema(_block: ScenarioBlock) {
  return outputSchema;
}

export function blockOutputsToGradeResult(
  block: ScenarioBlock,
  outputs: Record<string, ModelsBlockOutput>,
  mock?: boolean
): BlockGradeResult {
  const schema = getOutputSchema(block);
  const thingsSaidCorrectly = block.fields.rubric?.thingsSaidCorrectly ?? [];
  const thingsAvoidedSaying = block.fields.rubric?.thingsAvoidedSaying ?? [];
  const question =
    getBlockOutputAsString(outputs[schema.question.name]) ||
    block.fields.question?.entries?.at(0)?.script ||
    '';
  const totalPoints =
    getBlockOutputAsNumber(outputs[schema.totalPoints.name]) ||
    block.fields.points;
  let thingsSaidCorrectlyOutput = getBlockOutputAsString(
    outputs[schema.thingsSaidCorrectly.name]
  );
  let thingsAvoidedSayingOutput = getBlockOutputAsString(
    outputs[schema.thingsAvoidedSaying.name]
  );
  let result = getBlockOutputAsString(outputs[schema.result.name]);
  let earnedPoints = getBlockOutputAsNumber(outputs[schema.points.name]);
  if (mock) {
    result ||= randomPick(['succeeded', 'failed']);
    thingsSaidCorrectlyOutput ||= thingsSaidCorrectly
      .map((item) => `${item.title}: ${randomPick(['passed', 'failed'])}`)
      .join('\n');
    thingsAvoidedSayingOutput ||= thingsAvoidedSaying
      .map((item) => `${item.title}: ${randomPick(['passed', 'failed'])}`)
      .join('\n');
    if (!earnedPoints) {
      earnedPoints = result === 'succeeded' ? totalPoints : 0;
    }
  }
  const status =
    result === 'succeeded'
      ? EnumsBlockGradeResult.BlockGradeResultPassed
      : EnumsBlockGradeResult.BlockGradeResultFailed;
  return {
    blockId: block.id,
    blockType: block.type,
    status: status,
    label: capitalize(status),
    earnedPoints,
    totalPoints,
    context: [
      `Question asked: ${question}`,
      `Criteria:`,
      `Things that must be said correctly:`,
      thingsSaidCorrectlyOutput,
      `Things that should be avoided:`,
      thingsAvoidedSayingOutput,
    ].join('\n'),
  };
}
